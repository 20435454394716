@import 'src/styles/mixins';
@import 'src/styles/variables';

.wrapper {
  position: relative;
  top: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: getRem(7);
  width: getRem(20);

  &.active {
    top: getRem(7);
    gap: 0;
  }
}

.line {
  width: getRem(2);
  height: 100%;
  border-radius: getRem(8);
  margin-bottom: getRem(-10);

  @media screen and (min-width: $desktop-width) {
    margin-bottom: 0;
  }

  &.below {
    background-color: #d3d4df;
  }

  &.inside {
    background: linear-gradient(180deg, #1865ff 0%, rgba(24, 101, 255, 0) 100%);
  }

  &.above {
    background-color: $dsn-main;
  }
}

.bullet {
  width: getRem(6);
  height: getRem(6);
  border-radius: 50%;

  &.below {
    background-color: #d3d4df;
  }

  &.inside {
    display: grid;
    place-content: center;
    background-color: transparent;
    width: getRem(20);
    height: getRem(20);
    border: getRem(3) solid $dsn-main;

    &::after {
      content: '';
      width: getRem(6);
      height: getRem(6);
      background-color: $dsn-main;
      border-radius: 50%;
    }
  }

  &.above {
    background-color: transparent;
    width: getRem(20);
    height: getRem(20);
    background-color: $dsn-main;
  }
}
