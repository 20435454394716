.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  &_payment-progress {
    width: 100%;
    height: 100%;
    .container {
      width: 48px;
      height: 48px;
    }
    .circle {
      width: 9px;
      height: 9px;
    }
  }
}

.container {
  position: relative;
  width: 70px;
  height: 70px;
}

.circle {
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #1865ff;
  opacity: 0;
  animation: circle 0.8s steps(6) reverse infinite both;
}

.circle1 {
  @extend .circle;
  left: 40.01%;
  right: 40%;
  top: 0;
  bottom: 80%;
}

.circle2 {
  @extend .circle;
  left: 68.28%;
  right: 11.72%;
  top: 11.72%;
  bottom: 68.28%;
  animation-delay: 0.1s;
}

.circle3 {
  @extend .circle;
  left: 80%;
  right: 0;
  top: 40%;
  bottom: 40%;
  animation-delay: 0.2s;
}

.circle4 {
  @extend .circle;
  left: 68.28%;
  right: 11.72%;
  top: 68.29%;
  bottom: 11.71%;
  animation-delay: 0.3s;
}

.circle5 {
  @extend .circle;
  left: 40.01%;
  right: 40%;
  top: 80%;
  bottom: 0;
  animation-delay: 0.4s;
}

.circle6 {
  @extend .circle;
  left: 11.73%;
  right: 68.28%;
  top: 68.28%;
  bottom: 11.72%;
  animation-delay: 0.5s;
}

.circle7 {
  @extend .circle;
  left: 0;
  right: 79.99%;
  top: 40%;
  bottom: 39.99%;
  animation-delay: 0.6s;
}

.circle8 {
  @extend .circle;
  left: 11.73%;
  right: 68.28%;
  top: 11.72%;
  bottom: 68.28%;
  animation-delay: 0.7s;
}

@keyframes circle {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
