@import './src/styles/variables';

.container {
  display: inline-flex;
  justify-content: center;
  text-align: center;
  padding: 0 1.6rem;
  align-items: center;
  border-radius: 1.6rem;
  cursor: pointer;
  border: 1px solid transparent;
  transition: 0.3s;
  position: relative;
  text-transform: uppercase;
  &._rounded {
    border-radius: 5rem;
  }
}

.primary {
  background-color: #001ce6;
  color: #ffffff;

  &:active {
    background-color: #4164e9;
  }

  &:focus {
    box-shadow: 0 0 0 4px rgba(49, 130, 237, 0.4);
  }
}

.blue {
  background-color: $btn-blue;
  color: $txt-white;
  &:hover {
    background-color: $btn-blue-hover;
  }
  &:active {
    background-color: $btn-blue-active;
  }
}

.yellow {
  background-color: $btn-yellow;
  color: $txt-main;
  &:hover {
    background-color: $btn-yellow-hover;
  }
  &:active {
    background-color: $btn-yellow-active;
  }
}

.orange {
  background-color: $btn-orange;
  color: $txt-main;
  &:hover {
    background-color: $btn-orange-hover;
  }
  &:active {
    background-color: $btn-orange-active;
  }
}

.dark {
  background: #000000;
  color: white;
}

.outline- {
  &black {
    color: #000000;
    background-color: transparent;
    border: 1px solid #000000;

    &:hover {
      color: #333333;
      border-color: #333333;
    }
    &:active {
      color: #666666;
      border-color: #666666;
    }
    &:hover {
      border-color: #0042c6;
      color: #0042c6;
    }
  }

  &white {
    color: #ffffff;
    background-color: transparent;
    border-color: #ffffff;

    &:active {
      color: #c1c1d2;
      border-color: #c1c1d2;
    }

    &:focus {
      box-shadow: 0 0 0 4px rgba(49, 130, 237, 0.4);
    }
  }
}

.theme-light.primary {
  &:disabled {
    background-color: #c1c1d2;
  }
}

.theme-dark.primary {
  &:disabled {
    background-color: #666666;
  }
}

.theme-light.outline-black {
  &:disabled {
    color: #c1c1d2 !important;
    border-color: #c1c1d2 !important;
  }
}

.theme-dark.outline-white {
  &:disabled {
    color: #666666 !important;
    border-color: #666666 !important;
  }
}

.withoutPadding {
  padding: 0;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.fullWidth {
  width: 100% !important;
}

.container {
  @media screen and (min-width: 1024px) {
    font-weight: bold;
    font-size: 1.8rem;
    vertical-align: middle;
    line-height: normal;
    white-space: nowrap;
    text-transform: uppercase;
  }
  @media screen and (max-width: 1024px) {
    white-space: nowrap;
  }
}

.size- {
  &huge {
    height: 6.4rem;
    padding: 0 4rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: normal;
  }
  &large {
    height: 5.6rem;
    padding: 0 3.6rem;
    font-family: 'CodecPro-Bold', sans-serif;
    font-size: 1.6rem;
    vertical-align: middle;
    line-height: normal;
    @media screen and (max-width: 1024px) {
      width: 100%;
      height: 4.8rem;
      padding: 0 3.6rem;
      font-family: 'CodecPro', sans-serif;
      font-weight: normal;
      font-size: 1.6rem;
      vertical-align: middle;
      line-height: normal;
    }
  }

  &medium {
    height: 4.8rem;
    padding: 0 2.4rem;
    font-family: 'CodecPro-Bold', sans-serif;
    font-size: 1.4rem;
    vertical-align: middle;
    line-height: normal;
    @media screen and (max-width: 1024px) {
      height: 4rem;
      padding: 0 2.4rem;
      font-family: 'CodecPro', sans-serif;
      font-weight: normal;
      font-size: 1.4rem;
      vertical-align: middle;
      line-height: normal;
    }
  }

  &small {
    height: 4rem;
    padding: 0 1.6rem;
    font-family: 'CodecPro-Bold', sans-serif;
    font-size: 1.4rem;
    vertical-align: middle;
    line-height: normal;
    @media screen and (max-width: 1024px) {
      height: 4rem;
      padding: 0 1.6rem;
      font-family: 'CodecPro', sans-serif;
      font-weight: normal;
      font-size: 1.4rem;
      vertical-align: middle;
      line-height: normal;
    }
  }
}
