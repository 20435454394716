@import 'src/styles/mixins';

.sticker {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background-image: linear-gradient(146.31deg, #ff8d5c 14.73%, #fa2e6b 91.92%);
  clip-path: polygon(0% 0%, 100% 0, 100% 80%, 85% 100%, 0% 100%);
  height: getRem(26);
  font-family: 'CodecPro-Bold', sans-serif;
  font-size: getRem(12);
  line-height: getRem(10);
}
