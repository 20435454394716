.container {
  width: calc(25% - 0.8rem);
  border-radius: 5rem;
  height: 47.5rem;

  @media screen and (max-width: 1024px) {
    margin-top: 1.6rem;
    height: 20rem;
    width: auto;
  }
}
