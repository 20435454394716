.container {
  position: relative;
  margin-bottom: 8rem;

  @media screen and (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
    column-gap: 30rem;
    align-items: center;
    margin-bottom: 14rem;
  }

  &.reverse {
    flex-direction: row-reverse;
  }
  &.noGradient::before {
    display: none;
  }
  &::before {
    position: absolute;
    right: -40rem;
    content: '';
    width: 80rem;
    height: 80rem;
    z-index: -99999;
    transform: rotate(-20deg) scaleX(2) scaleY(1.5);
    @media screen and (max-width: 1024px) {
      top: 40rem;
      right: -20rem;
      content: '';
      width: 40rem;
      height: 40rem;
      transform: rotate(20deg) scaleX(2) scaleY(1.5);
    }
  }

  div {
    white-space: pre-wrap;
  }
}

.yellow::before {
  background: rgb(255, 161, 20);
  background: radial-gradient(
    circle,
    rgba(255, 161, 20, 0.8) 0%,
    rgba(255, 246, 40, 0.2) 25%,
    rgba(0, 0, 0, 0) 50%
  );
}

.green::before {
  background: rgb(89, 234, 164);
  background: radial-gradient(
    circle,
    rgba(89, 234, 164, 0.8) 0%,
    rgba(0, 86, 162, 0.2) 25%,
    rgba(0, 0, 0, 0) 50%
  );
}

.violet::before {
  background: rgb(179, 51, 222);
  background: radial-gradient(
    circle,
    rgba(179, 51, 222, 0.8) 0%,
    rgba(201, 48, 121, 0.2) 25%,
    rgba(0, 0, 0, 0) 50%
  );
}

.blue::before {
  background: rgb(24, 101, 255);
  background: radial-gradient(
    circle,
    rgba(24, 101, 255, 0.8) 0%,
    rgba(111, 0, 255, 0.2) 25%,
    rgba(0, 0, 0, 0) 50%
  );
}

.itemImage {
  width: 100%;
  height: 100%;
  @media screen and (max-width: 1024px) {
    width: 80rem;
  }
}

.itemContent {
  @media screen and (max-width: 1024px) {
    font-family: 'CodecPro-Bold', sans-serif;
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1.3;
    width: auto;
  }
}
