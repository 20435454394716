.arrows {
  display: none;

  @media screen and (min-width: 1023.9px) {
    display: flex;
  }

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
}

.arrow-circle {
  transform: rotate(180deg);
}
