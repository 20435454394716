.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
}

.circle {
  width: 1.5rem;
  height: 1.5rem;
  background-color: white;
  border-radius: 50%;
  opacity: 0.5;
  animation-duration: 1.5s;
  animation-name: circle;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.circle-one {
  @extend .circle;
}

.circle-two {
  @extend .circle;
  animation-delay: 0.5s;
}

.circle-three {
  @extend .circle;
  animation-delay: 1s;
}

@keyframes circle {
  33% {
    opacity: 1;
  }
  66% {
    opacity: 0.5;
  }
}
