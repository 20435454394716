@import 'src/styles/variables';

.container {
  position: relative;
  border-radius: 2.4rem;
  height: 20rem;
  background-repeat: no-repeat;
  text-decoration: none;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  a {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 2.4rem;
    text-decoration: none;
    color: #000000;
    height: 100%;
  }
}

.border {
  border: 1px solid #d3d4df;
}

.label {
  display: flex;
  align-items: center;
  @include font-codec(Bold, 14, 24);
  max-width: max-content;
  height: getRem(28);
  padding: 0 getRem(12);
  column-gap: getRem(14);
  border-radius: getRem(16);

  background: black;
  color: $txt-white;

  text-transform: uppercase;
  .bestseller {
    display: flex;
    align-items: center;
    @include font-codec(Bold, 10, 6);
    text-transform: lowercase;
    background-color: $pr-main;
    color: $txt-main;
    padding: getRem(4);
    margin: 0 getRem(-4);
    border-radius: getRem(16);
    .lightning {
      width: getRem(4);
      height: getRem(6);
      margin-right: getRem(4);
    }
  }
}

.textWrapper {
  width: 16.6rem;
}

.title {
  @include mobile_H3;
}

.light {
  color: black;
}

.dark {
  color: white;
}

.description {
  font-family: 'CodecPro', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 2.2rem;
}
