@import 'src/styles/mixins';

.discount {
  padding: getRem(2) getRem(6);
  font-family: 'CodecPro-Bold', sans-serif;
  font-size: getRem(14);
  line-height: getRem(18);
  border-radius: getRem(4);
  background-color: #59eaa4;

  @media screen and (max-width: 1024px) {
    padding-top: getRem(3);
    font-size: getRem(12);
    line-height: getRem(16);
  }
}

.discount-disabled {
  display: none;
}
