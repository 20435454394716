@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.wrapper {
  position: fixed;
  right: 2rem;
  bottom: 3rem;
  display: flex;
  flex-direction: column;
  gap: 42px;
  align-items: flex-end;
  z-index: 10000000;

  @media screen and (min-width: $desktop-width) {
    right: 4rem;
    bottom: 4rem;
  }
}

.widget-on-course {
  bottom: 7rem;

  @media screen and (min-width: $desktop-width) {
    bottom: 4rem;
  }
}

.tooltip {
  position: relative;
  display: none;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  background-color: #ffffff;
  border-radius: 24px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.24);

  @media screen and (min-width: $desktop-width) {
    gap: 16px;
    padding: 24px;
  }

  &::after {
    content: '';
    position: absolute;
    right: 35px;
    bottom: -23px;
    border: 15px solid transparent;
    border-top: 10px solid #ffffff;
    border-right: 20px solid #ffffff;
    filter: drop-shadow(0 8px 4px rgba(0, 0, 0, 0.24));
  }
}

.open {
  display: flex;
}

.close-tooltip {
  display: flex;
  width: 12px;
  height: 12px;

  @media screen and (min-width: $desktop-width) {
    width: 15px;
    height: 15px;
  }
}

.inner {
  @include font-codec(Regular, 14, 20);
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;

  @media screen and (min-width: $desktop-width) {
    @include font-codec(Regular, 16, 24);
    font-size: 16px;
    line-height: 24px;
  }
}

.status {
  width: 8px;
  height: 8px;
  background-color: #60d66a;
  border-radius: 50%;
}

.online {
  padding: 4px 8px;
  background-color: #f4f5f6;
  border-radius: 40px;
  margin-right: 24px;
  margin-left: 8px;
}

.text {
  @include font-codec(Bold, 16, 20);
  font-size: 16px;
  line-height: 20px;
  padding-left: 16px;

  @media screen and (min-width: $desktop-width) {
    @include font-codec(Bold, 18, 24);
    font-size: 18px;
    line-height: 24px;
  }
}

.wa-button {
  width: 64px;
  height: 64px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.4);
  border-radius: 50%;

  &:hover {
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.6);
  }
}

.loader {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: white;
  z-index: 10000000;
  animation: fade 1s ease-in-out;

  div div div {
    background-color: #138c7e;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
