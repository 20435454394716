@import 'src/styles/variables';

.container {
  position: relative;
  width: 60rem;
  color: #000000;

  .placeholder {
    position: relative;
    display: flex;
    align-items: center;
    height: 5.5rem;
    padding: 0 3rem;
    background-color: #f4f5f6;
    border-radius: 1.6rem;
    border: solid 1px #000000;
    font-family: 'CodecPro-Bold';
    font-size: 1.6rem;
    vertical-align: middle;
    line-height: normal;
    text-transform: uppercase;

    &-selected {
      color: $btn-blue-active;
      border: solid 1px $btn-blue-active;
    }

    &-open {
      background-color: #ffffff;
    }
  }

  .icon {
    position: absolute;
    right: 3rem;
    width: 1.8rem;
    height: auto;
    transition: transform 0.3s;
  }

  .icon-rotate {
    transform: rotate(180deg);
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
  .options {
    display: none;
  }
  &.isOpen {
    background-color: #ffffff;
    border-color: black;
    color: black;

    .options {
      display: flex;
      flex-direction: column;
      font-size: 1.6rem;
    }

    .optionItem {
      display: flex;
      align-items: center;
      height: 5.5rem;
      width: 100%;
      padding: 0 3rem;
      border-radius: 1.6rem;

      &:hover,
      &:focus {
        background-color: #f4f5f6;
        color: black;
      }
    }
  }
}
