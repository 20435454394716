@import 'src/styles/variables';

.container {
  position: relative;
}
.formGroup {
  margin-bottom: 3.2rem;
}
.formLabel {
  @include desktop_H4;
  max-width: 24rem;
  margin-bottom: 1.2rem;
}
.formLabelWithInfo {
  display: flex;
}
.formLabelWithSwitch {
  display: flex;
  justify-content: space-between;
}
.radioItem {
  margin-bottom: 1.2rem;
}
.formInputRange {
  @include desktop_control_16;
  margin-bottom: 5.4rem;
  margin-top: 2.1rem;
}
.buttons {
  display: flex;
  justify-content: space-between;
  .button {
    width: 16.3rem;
  }
}
.infoModalTitle {
  font-family: 'CodecPro-Bold', sans-serif;
  font-size: 2.4rem;
  line-height: 2.4rem;
  margin-bottom: 4rem;
}
.infoModalTextTitle {
  font-family: 'CodecPro-Bold', sans-serif;
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin: 3.2rem 0 1.6rem;
}
.infoModalP {
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin: 1.6rem 0;
}
