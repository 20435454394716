@import 'src/styles/variables';

.wrapper {
  padding: getRem(16);
  background-color: #aed9ff;
  border-radius: getRem(24);
  margin: 0 -$mobilePaddingContainer getRem(64);
  @media screen and (min-width: 1025px) {
    padding: getRem(56);
    border-radius: getRem(40);
    margin: 0 0 getRem(104);
  }
}

.container {
  padding: getRem(24);
  background-color: white;
  border-radius: getRem(16);
  @media screen and (min-width: 1025px) {
    padding: getRem(40);
    border-radius: getRem(32);
  }
}

.title {
  white-space: pre-wrap;
  margin-bottom: getRem(16);
  @media screen and (min-width: 1025px) {
    white-space: unset;
  }
}

.title.noSubTitle {
  margin-bottom: getRem(24);
  @media screen and (min-width: 1025px) {
    margin-bottom: getRem(40);
  }
}

.subTitle {
  @include font-codec(Bold, 18, 24);
  margin-bottom: getRem(24);
  white-space: pre-wrap;
  @media screen and (min-width: 1025px) {
    @include font-codec(Bold, 24, 32);
    margin-bottom: getRem(40);
    white-space: unset;
  }
}

.inputRow {
  @media screen and (min-width: 1025px) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: getRem(16);
    margin-bottom: getRem(12);
  }
  .button {
    @include font-codec(Bold, 14, 24);
    background-color: black;
    width: 100%;
    height: getRem(56);
    @media screen and (min-width: 1025px) {
      @include font-codec(Bold, 16, 24);
      width: auto;
      height: getRem(72);
    }
  }
}

.inputWrapper {
  margin-bottom: getRem(8);
  @media screen and (min-width: 1025px) {
    margin-bottom: 0;
  }
}

.input {
  @include font-codec(Regular, 16, 20);
  @media screen and (min-width: 1025px) {
    @include font-codec(Regular, 18, 24);
    height: getRem(72);
  }
}

.agreementContainer {
  margin-bottom: getRem(12);
  @media screen and (min-width: 1025px) {
    align-items: center;
    margin-bottom: 0;
  }
}

.agreement {
  @include font-codec(Regular, 16, 24);
  font-weight: normal;
  @media screen and (max-width: 1024px) {
    @include font-codec(Regular, 14, 20);
  }
  a {
    @include font-codec(Bold, 16, 24);
    font-weight: bold;
    color: $txt-main;
    @media screen and (max-width: 1024px) {
      @include font-codec(Bold, 14, 20);
    }
  }
}

.agreement.agreementError {
  color: #fb4571;
  a {
    color: #fb4571;
  }
}
