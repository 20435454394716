@import 'src/styles/variables';

.wrapper {
  @include standard-block-margin();
}

.title {
  @media screen and (min-width: 1024px) {
    @include desktop_H1;
    max-width: 60rem;
    height: auto;
    position: relative;
    text-transform: lowercase;
  }

  @media screen and (max-width: 1024px) {
    @include mobile_H2;
    max-width: 32rem;
    position: relative;
    text-transform: lowercase;
  }
}

.container {
  display: grid;
  position: relative;

  @media screen and (min-width: 1024px) {
    margin-top: 8.4rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 21.3rem;
    grid-row-gap: 7.8rem;
  }

  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
    margin-top: 5.2rem;
    grid-gap: 5.5rem;
    grid-auto-rows: minmax(2.8rem, auto);
  }
}

.row {
  display: flex;
  justify-content: space-between;
}

.item {
  display: flex;
  position: relative;
  min-height: 30px;
  max-height: 60px;
  img {
    object-fit: contain;
    height: auto;
    width: 100%;
    @media screen and (max-width: 1024px) {
      max-width: 14.4rem;
    }
  }
}
