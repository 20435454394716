@import 'src/styles/variables';

.container {
  width: 100%;
}

.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 1.6rem;
  border: 1px solid #000;

  &.no-border {
    border-color: transparent;
  }

  &.error {
    border: 1px solid #fb4571;
  }
  &.disabled {
    border: 1px solid $stroke-secondary;
  }
}

.before {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 4.4rem;
}

.input {
  width: 100%;
  border: none;
  border-radius: 1.6rem;
  height: 6.4rem;
  padding: 1.6rem;
  font-weight: normal;
  font-size: 2.4rem;
  line-height: 3.2rem;
}

.message {
  @include font-codec(Regular, 16, 20);
  color: #fb4571;
  margin-top: getRem(8);
  @media screen and (min-width: 1024px) {
    @include font-codec(Regular, 18, 24);
    margin-top: getRem(12);
  }
}

.checkbox-container {
  display: flex;
  position: relative;
  cursor: pointer;
  user-select: none;

  .checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    display: flex;
    justify-content: center;
    align-items: center;
    width: getRem(32);
    height: getRem(32);
    border: 1px solid black;
    border-radius: getRem(4);
    background-color: transparent;
    &.error {
      border: 1px solid red;
    }
  }

  &:hover .checkmark {
    background-color: $bg-secondary;
    &::after {
      opacity: 1;
    }
  }
  .checkbox:checked ~ .checkmark {
    background-color: $dsn-main;
    border-color: transparent;
    &::after {
      opacity: 1;
    }
  }
  .checkmark::after {
    position: absolute;
    width: getRem(6);
    height: getRem(12);
    margin-top: getRem(-3);
    border: solid white;
    content: '';
    border-width: 0 getRem(3) getRem(3) 0;
    transform: rotate(45deg);
    opacity: 0;
  }
}
