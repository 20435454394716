@import 'src/styles/variables';

.container {
  padding: 0 1.2rem;

  :global {
    .rc-slider-handle {
      width: 2.4rem;
      height: 2.4rem;
      border: none;
      background: $colorAccentBlueMain;
      margin-top: -1rem;
    }

    .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
      box-shadow: none;
    }

    .rc-slider-track {
      height: 0.5rem;
      background: $colorAccentBlueMain;
    }

    .rc-slider-rail {
      height: 0.5rem;
      background: $colorGrey;
    }

    .rc-slider-dot {
      display: none;
      bottom: -0.6rem;
      margin-left: -0.8rem;
      width: 1.6rem;
      height: 1.6rem;
      border: 2px solid #e9e9e9;
    }

    .rc-slider-dot-active {
      border-color: $colorAccentBlueMain;
    }

    .rc-slider-mark {
      font-size: 1.2rem;
    }

    .rc-slider-mark-text {
      color: #000;
      font-weight: normal;
    }
  }
}
