@import 'src/styles/variables';

.container {
  position: relative;
}

.ratingStars {
  position: absolute;
  left: 0;
  object-fit: cover;
  object-position: left;
}
