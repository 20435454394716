.icon {
  margin-left: 1.2rem;
  width: 1.2rem;
  height: 1.2rem;
  margin-bottom: 1.2rem;
  margin-top: 0.5rem;
  cursor: pointer;
}

.tooltip {
  &:global(.rc-tooltip) {
    max-width: 55.2rem;
    opacity: 1;
  }

  :global {
    .rc-tooltip-arrow {
      display: none;
    }

    .rc-tooltip-inner {
      background: #ffffff;
      box-shadow: 0 0 2rem rgba(0, 0, 0, 0.2);
      padding: 3rem 2.4rem;
      border-radius: 0;
      color: #000;
    }
  }
}
