.gradient {
  position: absolute;
  z-index: -10;
}

.gradient-aqua {
  left: -27.5rem;
  top: -34rem;
  width: 130rem;
  height: 150.8rem;
  background-image: url('~/public/images/FooterGradientDesktop-1.svg');
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotate(29deg);

  @media screen and (max-width: 1024px) {
    left: 20.5rem;
    top: 10rem;
    width: 50.4rem;
    height: 40.2rem;
  }
}

.gradient-blue {
  right: -5.5rem;
  top: 28.3rem;
  width: 107rem;
  height: 69.5rem;
  background-image: url('~/public/images/FooterGradientDesktop-2.svg');
  background-size: contain;
  background-repeat: no-repeat;

  @media screen and (max-width: 1024px) {
    left: -5.5rem;
    top: 22.3rem;
    right: auto;
    width: 67rem;
    height: 53rem;
  }
}

.gradient-aqua-light {
  top: -34rem;
  right: -9.5rem;
  width: 58rem;
  height: 75rem;
  background-image: url('~/public/images/FooterGradientDesktop-1.svg');
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotate(60.07deg);

  @media screen and (max-width: 1024px) {
    display: none;
  }
}
