@import 'src/styles/variables';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: getRem(16);
  margin-bottom: getRem(8);
  @media screen and (min-width: 1024px) {
    margin-bottom: getRem(12);
  }
}

.title {
  @include font-codec(Bold, 18, 25);
  @media screen and (min-width: 1024px) {
    @include font-codec(Bold, 24, 32);
  }
}

.sub-title {
  @include font-codec(Regular, 16, 24);
  color: $txt-add;
  padding-bottom: getRem(12);
  @media screen and (max-width: 1024px) {
    padding-bottom: getRem(8);
  }
}

.cross {
  transition: transform 0.3s ease-in-out;
  @media screen and (min-width: 1024px) {
    width: getRem(24);
  }
  &.active {
    transform: rotate(-45deg);
  }
}

.input-wrapper {
  height: getRem(65);
  overflow: hidden;
  margin-bottom: getRem(16);
  transition: all 0.3s ease-in-out;
  &.hidden {
    height: 0;
    margin-bottom: getRem(8);
  }
}

.input {
  @media screen and (max-width: 1024px) {
    @include font-codec(Regular, 16, 20);
  }
  height: getRem(60);
}

.valid {
  position: relative;
  border: 1px solid #59eaa4;
  &::before {
    position: absolute;
    right: getRem(24);
    top: getRem(24);
    content: '';
    background-image: url('~/public/images/checkmark.svg');
    background-repeat: no-repeat;
    background-size: contain;
    height: getRem(12);
    width: getRem(16);
    @media screen and (min-width: 1024px) {
      right: getRem(26);
      top: getRem(26);
    }
  }
}
.invalid {
  border: 1px solid red;
  &::before {
    position: absolute;
    right: getRem(24);
    top: getRem(24);
    content: '';
    background-image: url('~/public/images/cross-red.svg');
    background-repeat: no-repeat;
    background-size: contain;
    height: getRem(14);
    width: getRem(14);
    @media screen and (min-width: 1024px) {
      right: getRem(20);
      top: getRem(20);
      height: getRem(18);
      width: getRem(18);
    }
  }
}

.message {
  @include font-codec(Regular, 14, 20);
  color: $txt-add;
  margin-top: getRem(-8);
  margin-bottom: getRem(12);
  @media screen and (min-width: 1024px) {
    @include font-codec(Regular, 16, 24);
  }
}

.header-info {
  display: flex;
  position: relative;
}

.with-tooltip {
  cursor: pointer;

  &:hover .tooltip {
    opacity: 1;
  }

  &::after {
    width: getRem(12);
    height: getRem(12);
    margin-left: getRem(4);
    content: url('~/public/images/info-tooltip.svg');
    @media screen and (min-width: 1024px) {
      margin-left: getRem(8);
    }
  }
}

.tooltip {
  position: absolute;
  bottom: getRem(30);
  left: getRem(250);

  @include font-codec(Regular, 16, 24);

  background: white;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.2);

  width: getRem(314);
  padding: getRem(12);

  opacity: 0;

  pointer-events: none;
  transition: opacity ease-in-out 300ms;

  z-index: 999;
  @media screen and (max-width: 1024px) {
    width: getRem(248);
    left: getRem(30);
  }
}
