.toggle-expand {
  border: none;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  left: 0;
  bottom: 0;
  padding: 1.5rem;
  width: 100%;
  height: 15rem;
  position: absolute;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgb(255, 255, 255) 24%,
    rgba(255, 255, 255, 0) 100%
  );

  img {
    position: absolute;
    bottom: 37px;
  }
}
