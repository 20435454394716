@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  margin-bottom: getRem(104);
  @media screen and (max-width: 1024px) {
    margin-bottom: getRem(64);
  }
}

.title {
  @include font-codec(Bold, 64, 64);
  margin-bottom: getRem(44);
  @media screen and (max-width: 1024px) {
    @include font-codec(Bold, 40, 40);
    padding-right: 5rem;
  }
}

.accordion {
  margin-left: -$courseDesktopPaddingContainer;
  margin-right: -$courseDesktopPaddingContainer;
  @media screen and (max-width: 1024px) {
    margin-left: -$mobilePaddingContainer;
    margin-right: -$mobilePaddingContainer;
  }
}
