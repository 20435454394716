@import './src/styles/variables';

.banner {
  position: relative;
  display: flex;
  gap: 40rem;
  padding: getRem(151) $mobilePaddingContainer 0;
  white-space: pre-line;

  @media screen and (min-width: $desktop-width) {
    margin-top: getRem(-64);
    padding: getRem(108) $desktopPaddingContainer getRem(125);
    gap: 0;
    justify-content: space-between;
  }

  &.main-gradient {
    background-color: $dsn-main;
    background-image: url('~/public/images/banner-gradient/main-gradient-mobile.svg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top;

    @media screen and (min-width: $desktop-width) {
      background-image: url('~/public/images/banner-gradient/main-gradient-desktop.svg');
      background-size: contain;
      background-position: right;
    }
  }

  &.about-gradient {
    background-color: $dsn-main;
    background-image: url('~/public/images/banner-gradient/about-gradient-mobile.svg');
    background-size: cover;
    background-repeat: no-repeat;

    @media screen and (min-width: $desktop-width) {
      background-image: url('~/public/images/banner-gradient/about-gradient-desktop.svg');
    }
  }

  &.default-gradient {
    background-color: $dsn-main;
    background-image: url('~/public/images/banner-gradient/default-gradient.svg');
    background-size: cover;
    background-repeat: no-repeat;
  }

  &.cc-gradient {
    background-color: $dsn-main;
    background-image: url('~/public/images/banner-gradient/cc-gradient-mobile.svg');
    background-size: cover;

    @media screen and (min-width: $desktop-width) {
      background-image: url('~/public/images/banner-gradient/cc-gradient-desktop.svg');
    }
  }
}

.banner-main {
  margin-top: getRem(-60);
  padding-top: getRem(261);
}

.text-wrapper {
  display: flex;
  flex-direction: column;
  gap: getRem(24);
  @media screen and (min-width: $desktop-width) {
    gap: getRem(80);
  }
}

.text-wrapper-main {
  align-self: flex-end;
}

.title {
  color: $txt-white;
  font-size: 14rem;
  line-height: 11.2rem;
}

.inner {
  max-width: 39rem;
}

.text {
  @include font-codec(Regular, 18, 24);
  color: $txt-white;
  @media screen and (min-width: $desktop-width) {
    @include font-codec(Regular, 24, 32);
  }
}

.text-on-main {
  @include font-codec(Bold, 28, 28);
  color: $txt-white;
  @media screen and (min-width: $desktop-width) {
    @include font-codec(Bold, 40, 40);
  }
}

.button {
  @include font-codec(Bold, 14, 20);
  margin-top: getRem(16);
  padding: getRem(18) getRem(40);
  height: auto;
  width: auto;
  text-transform: uppercase;
  @media screen and (min-width: $desktop-width) {
    @include font-codec(Bold, 16, 24);
    padding: getRem(21) getRem(40) getRem(19);
    margin-top: getRem(24);
  }
}

.logo-main {
  position: absolute;
  width: getRem(1520);
  height: getRem(178);
  top: getRem(130);
}

.logo {
  object-fit: cover;
}

.image-wrapper {
  position: relative;
  width: auto;
  height: 100%;
}

.image {
  object-fit: contain;
}
