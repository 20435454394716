@import 'src/styles/variables';

.wrapper {
  background-color: $bg-main;
  border-radius: getRem(16);
  padding: getRem(16) getRem(24);
  @media screen and (min-width: 1024px) {
    border-radius: getRem(24);
    padding: getRem(32);
  }
  &.inactive {
    cursor: pointer;
  }
}

.title {
  margin-right: getRem(24);
  @include font-codec(Bold, 18, 24);
  @media screen and (min-width: 1024px) {
    @include font-codec(Bold, 24, 32);
  }
}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.container {
  margin-top: getRem(32);
  overflow: hidden;
  transition: all 0.3s ease-in-out;

  &.disabled {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  @media screen and (min-width: 1024px) {
    margin-top: getRem(40);
  }
  @media screen and (max-width: 1024px) {
    margin-bottom: getRem(8);
  }
}

.itemIcon {
  width: getRem(34);
  min-width: getRem(34);
  height: auto;
  transition: all 300ms ease-in-out;
  transform: rotate(45deg);
  @media screen and (max-width: 1024px) {
    width: getRem(34);
  }
  &.active {
    transform: rotate(0);
  }
}
