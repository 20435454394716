@import 'src/styles/variables';

.wrapper {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 999;
}

.container {
  display: flex;
  justify-content: space-between;
  padding: getRem(8);
  background-color: $bg-main;
  border-radius: getRem(6);
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  transition: ease-in-out 0.3s;
  @media screen and (min-width: 1025px) {
    border-radius: getRem(20);
  }
  &.fixed {
    position: fixed;
  }
  &.hidden {
    @extend .fixed;
    transform: translateY(-10rem);
  }
}
