/*********************************************************/
/******************  COLORS VARIABLES  ********************/
/*********************************************************/

$txt-main: var(--txt-main);
$txt-white: var(--txt-white);
$txt-add: var(--txt-add);
$txt-gold: var(--txt-gold);

$bg-main: var(--bg-main);
$bg-secondary: var(--bg-secondary);
$bg-brand: var(--bg-brand);
$bg-brand-bg-secondary: var(--bg-brand-bg-secondary);

$stroke-main: var(--stroke-main);
$stroke-secondary: var(--stroke-secondary);

$dsn-main: var(--dsn-main);
$dsn-add: var(--dsn-add);
$pr-main: var(--pr-main);
$pr-add: var(--pr-add);
$mrkt-main: var(--mrkt-main);
$mrkt-add: var(--mrkt-add);
$gd-main: var(--gd-main);
$gd-add: var(--gd-add);

$bg-dsn-main: var(--bg-dsn-main);
$bg-dsn-main-r: var(--bg-dsn-main-r);
$bg-dsn-add: var(--bg-dsn-add);
$bg-pr-main: var(--bg-pr-main);
$bg-pr-add: var(--bg-pr-add);
$bg-mrkt-main: var(--bg-mrkt-main);
$bg-mrkt-main-r: var(--bg-mrkt-main-r);
$bg-mrkt-add: var(--bg-mrkt-add);
$bg-gd-main: var(--bg-gd-main);
$bg-gd-add: var(--bg-gd-add);

$gd-blue: var(--gd-blue);

$btn-blue: var(--btn-blue);
$btn-blue-hover: var(--btn-blue-hover);
$btn-blue-active: var(--btn-blue-active);

$btn-yellow: var(--btn-yellow);
$btn-yellow-hover: var(--btn-yellow-hover);
$btn-yellow-active: var(--btn-yellow-active);

$btn-orange: var(--btn-orange);
$btn-orange-hover: var(--btn-orange-hover);
$btn-orange-active: var(--btn-orange-active);
