.digit {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 2rem;
  background-color: #1865ff;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 1rem;
  width: 35rem;
  height: 6rem;

  font-size: 3.8rem;
  font-family: 'CodecPro-BoldItalic', sans-serif;
  color: white;

  &.blue {
    background-image: linear-gradient(
      180deg,
      #d8b8ff 0%,
      #c0afff 34.3%,
      #3378ff 100%
    );
    background-color: transparent;
  }

  &.yellow {
    background-image: linear-gradient(
      180deg,
      #fddd33 0%,
      #fdd439 23.5%,
      #ff9e5b 68%,
      #ff9263 100%
    );
    background-color: transparent;
  }
  &.violet {
    background-image: linear-gradient(
      180deg,
      #fa4470 7.08%,
      #e6389e 33%,
      #c524e8 68%,
      #bf21f5 100%
    );
    background-color: transparent;
  }
  &.green {
    background-image: linear-gradient(
      180deg,
      #c6e3ff 0%,
      #a0e5e0 29.2%,
      #58e9a3 63.78%,
      #58e971 100%,
      #58e9a3 100%
    );
    background-color: transparent;
  }

  @media screen and (max-width: 1024px) {
    font-size: 3rem;
    width: 30rem;
    height: 5rem;
  }
}
