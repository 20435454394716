@import 'src/styles/variables';

.container {
  margin-top: -6.4rem;
  padding: getRem(68) $mobilePaddingContainer getRem(44);
  background: url('~/public/images/text-banner-background.svg') center no-repeat;
  background-size: cover;
  color: $txt-white;
  @media screen and (min-width: 1024px) {
    padding: getRem(198) $desktopPaddingContainer;
    height: getRem(752);
  }
}

.title {
  @include font-codec(Bold, 40, 40);
  white-space: pre-wrap;
  margin-bottom: getRem(16);
  @media screen and (min-width: 1024px) {
    @include font-codec(Bold, 140, 112);
    margin-bottom: getRem(24);
  }
}

.text {
  @include font-codec(Regular, 18, 24);
  @media screen and (min-width: 1024px) {
    @include font-codec(Regular, 24, 32);
    width: getRem(624);
  }
}
