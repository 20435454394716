@import './src/styles/variables';

.container {
  position: relative;
  z-index: 5;
  border-radius: 2rem 2rem 0 0;
  border-top: none;
  border-bottom: none;
  margin-top: -2rem;

  &.burger {
    margin-top: 2rem;
    @media screen and (min-width: $desktop-width) {
      margin-top: 0;
    }
  }
}

.nav-elem {
  @include font-codec(Bold, 28, 28);
  display: block;

  height: 6.4rem;
  background-color: transparent;
  border-top: 0.1rem solid #000000;
  border-radius: 2rem 2rem 0 0;

  text-decoration: none;

  color: #000000;

  &:hover {
    transition-duration: 0.2s;
    transform: translateY(-1rem);
  }
}

.footer-elem {
  @include desktop_H1;

  @media screen and (max-width: 1024px) {
    font-size: 2.8rem;
    line-height: 2.8rem;
  }
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6.4rem;
}
