@import 'src/styles/variables';
@import 'src/styles/mixins';

.item {
  padding: 0 $courseDesktopPaddingContainer;
  &:nth-child(n + 2) {
    border-top: 1px solid black;
    border-radius: $courseDesktopPaddingContainer $courseDesktopPaddingContainer
      0 0;
  }

  @media screen and (max-width: 1024px) {
    padding: 0 $mobilePaddingContainer;
    &:nth-child(n + 2) {
      border-top: 1px solid black;
      border-radius: $mobilePaddingContainer $mobilePaddingContainer 0 0;
    }
  }
}

.item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: getRem(20) 0;
  cursor: pointer;

  @media screen and (max-width: 1024px) {
    padding: getRem(24) 0;
  }
}

.item-name {
  @include font-codec(Bold, 32, 36);
  display: flex;
  text-transform: lowercase;

  @media screen and (max-width: 1023px) {
    @include font-codec(Bold, 24, 24);
  }

  p {
    @include font-codec(Bold, 32, 36);

    @media screen and (max-width: 1023px) {
      @include font-codec(Bold, 24, 24);
    }
  }
}

.item-number {
  margin-right: 2rem;
  font-size: 2rem;
  font-weight: bold;

  @media screen and (max-width: 1024px) {
    display: none;
  }
}

.item-icon {
  width: 5rem;
  height: auto;
  border-radius: 50%;
  transition: all 300ms ease-in-out;

  @media screen and (max-width: 1024px) {
    width: 3.5rem;
  }

  &.active {
    transform: rotate(-45deg);
  }

  &.blue {
    color: white;
    background: linear-gradient(0deg, #d8b8ff 0%, #c0afff 34.3%, #3378ff 100%);
  }

  &.yellow {
    background: linear-gradient(
      180deg,
      #fddd33 0%,
      #fdd439 23.5%,
      #ff9e5b 68%,
      #ff9263 100%
    );
  }

  &.green {
    background: linear-gradient(
      180deg,
      #c6e3ff 0%,
      #a0e5e0 29.2%,
      #58e9a3 63.78%,
      #58e971 100%,
      #58e9a3 100%
    );
  }

  &.violet {
    color: white;
    background: linear-gradient(
      0deg,
      #fa4470 7.08%,
      #e6389e 33%,
      #c524e8 68%,
      #bf21f5 100%
    );
  }
}

.item-content {
  height: 0;
  padding: 0;
  margin: 0;
  font-size: 2.5rem;
  line-height: 1;
  white-space: pre-wrap;
  box-sizing: border-box;
  overflow: hidden;
  transition: all 300ms ease-in-out;

  &.active {
    height: auto;
    margin-bottom: getRem(64);
  }
}
