@import 'src/styles/variables';
@import 'src/styles/mixins';
@import 'src/styles/variables/fonts';

.overlay {
  position: fixed;
  top: 0;
  z-index: 1000;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  @media screen and (max-width: 1024px) {
    display: block;
  }
}

.container {
  width: 100rem;
  max-height: 70.8rem;
  background-color: white;
  margin: auto;
  padding: $desktopPaddingContainer;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 3.2rem;
  @media screen and (max-width: 1024px) {
    width: auto;
    max-height: none;
    margin: 0 auto;
    min-height: 100%;
    padding: 3rem 1.6rem;
    position: relative;
    border-radius: 0;
  }
}

.close {
  position: absolute;
  top: 4rem;
  right: 4rem;
  height: getRem(48);
  width: getRem(48);
  cursor: pointer;
  z-index: 1;
  @media screen and (max-width: 1024px) {
    top: 3rem;
    right: 2.5rem;
    height: getRem(32);
    width: getRem(32);
  }
}

.title {
  margin-bottom: 4rem;
  margin-right: 4rem;
  @media screen and (min-width: 1024px) {
    @include font-codec(Bold, 40, 40);
  }
  @media screen and (max-width: 1024px) {
    @include font-codec(Bold, 28, 28);
  }
}
