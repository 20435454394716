@import 'src/styles/variables';

.filterSearch {
  position: relative;
  display: flex;
  align-items: flex-end;
  margin-bottom: 2.4rem;
  @media screen and (max-width: 1024px) {
    display: block;
    width: 50%;
  }
}

.searchField {
  font-size: 2.3rem;
  width: 38rem;
  height: 6.4rem;
  padding-left: 5rem;
  border-radius: 1.5rem;
  border: 1px solid black;
  @media screen and (max-width: 1024px) {
    width: 100%;
    height: 6rem;
    text-transform: uppercase;
    font-size: 1.5rem;
    font-family: 'CodecPro-Bold', sans-serif;
    padding-top: 0.2rem;
  }
  &::placeholder {
    color: black;
  }
}

.searchIcon {
  position: absolute;
  left: 1.5rem;
  bottom: 2.3rem;
  height: 2.5rem;
  width: auto;
  @media screen and (max-width: 1024px) {
    left: 2rem;
    bottom: 2.2rem;
    height: 2.1rem;
  }
}
