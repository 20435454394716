@import 'src/styles/variables';

.container {
  display: flex;
  align-items: center;
}

.input {
  display: none;

  &:checked {
    & ~ .marker {
      background-color: $colorAccentBlueMain;

      &:before {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.field {
  position: relative;
}

.marker {
  display: flex;
  align-items: center;
  justify-content: center;
  width: getRem(24);
  min-width: getRem(24);
  height: getRem(24);
  background-color: #e8eaf1;
  border-radius: 50%;
  position: relative;
  transition: $transitionBase;

  &:before {
    content: '';
    width: getRem(10);
    min-width: getRem(10);
    height: getRem(10);
    border-radius: 50%;
    background-color: #fff;
    opacity: 0;
    visibility: hidden;
  }
}

.label {
  margin-left: 1.2rem;
  text-transform: lowercase;
}
